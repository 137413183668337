import React from "react";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <section
      id="footer"
      className="flex flex-col items-center justify-center h-full pb-10 mt-0 bg-black poppins-medium"
    >
      <div className="flex flex-col items-center mt-28 justify-normal">
        <svg
          className="logo-footer"
          viewBox="0 0 666 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_454_451)">
            <g filter="url(#filter0_d_454_451)">
              <path
                d="M38.0368 52.8847V77.9352C36.1213 78.281 34.3724 78.4532 32.7903 78.4532C23.4216 78.4532 15.6144 75.2602 9.36865 68.8728C3.12289 62.4861 0 54.4812 0 44.8582C0 35.4937 3.1437 27.5536 9.4311 21.0374C15.7601 14.5212 23.4424 11.2632 32.4779 11.2632C42.5543 11.2632 50.4865 14.3271 56.2743 20.4549C62.1038 26.5395 65.0186 34.8679 65.0186 45.4406V77.094H41.6593V47.1883C41.6593 43.7794 40.8472 41.0607 39.2233 39.0326C37.6412 37.0041 35.4968 35.9901 32.7903 35.9901C30.3335 35.9901 28.21 36.918 26.4196 38.7735C24.6291 40.6291 23.7339 42.8301 23.7339 45.3762C23.7339 48.0515 24.5458 50.2525 26.1697 51.9785C27.7936 53.7045 29.8547 54.5677 32.3531 54.5677C34.3935 54.5677 36.2878 54.0069 38.0368 52.8847Z"
                fill="#2603FE"
              />
            </g>
            <g filter="url(#filter1_d_454_451)">
              <path
                d="M97.996 89.7164H74.6367V35.8608C74.6367 25.9782 77.1349 17.9305 82.1316 11.7162C85.0046 8.13443 88.6482 5.28633 93.0618 3.17177C97.5172 1.05727 102.076 0 106.74 0C116.025 0 123.853 3.23651 130.224 9.70951C136.595 16.1826 139.78 24.1227 139.78 33.5302C139.78 42.6787 136.616 50.5762 130.286 57.2219C123.958 63.8676 116.463 67.1901 107.802 67.1901C106.386 67.1901 104.304 67.0389 101.556 66.7371V40.0678C103.471 41.6647 105.387 42.4631 107.302 42.4631C109.717 42.4631 111.778 41.5355 113.485 39.6797C115.235 37.8241 116.109 35.6235 116.109 33.0772C116.109 30.531 115.213 28.3733 113.423 26.604C111.632 24.7918 109.488 23.8854 106.99 23.8854C100.994 23.8854 97.996 28.1793 97.996 36.767V89.7164Z"
                fill="#2603FE"
              />
            </g>
            <g filter="url(#filter2_d_454_451)">
              <path
                d="M172.746 89.7164H149.387V35.8608C149.387 25.9782 151.885 17.9305 156.882 11.7162C159.755 8.13443 163.398 5.28633 167.812 3.17177C172.267 1.05727 176.826 0 181.49 0C190.775 0 198.603 3.23651 204.974 9.70951C211.345 16.1826 214.53 24.1227 214.53 33.5302C214.53 42.6787 211.365 50.5762 205.036 57.2219C198.708 63.8676 191.213 67.1901 182.552 67.1901C181.136 67.1901 179.054 67.0389 176.306 66.7371V40.0678C178.221 41.6647 180.137 42.4631 182.052 42.4631C184.467 42.4631 186.528 41.5355 188.235 39.6797C189.985 37.8241 190.859 35.6235 190.859 33.0772C190.859 30.531 189.963 28.3733 188.173 26.604C186.382 24.7918 184.238 23.8854 181.74 23.8854C175.744 23.8854 172.746 28.1793 172.746 36.767V89.7164Z"
                fill="#2603FE"
              />
            </g>
            <g filter="url(#filter3_d_454_451)">
              <path
                d="M309.587 77.7735H286.228V42.9484C286.228 40.2731 285.957 38.4179 285.416 37.3818C284.875 36.3461 283.896 35.8284 282.48 35.8284C279.857 35.8284 278.545 38.2233 278.545 43.0135V77.7735H255.186V42.9484C255.186 40.2731 254.895 38.4179 254.312 37.3818C253.771 36.3461 252.792 35.8284 251.376 35.8284C248.795 35.8284 247.504 38.2233 247.504 43.0135V77.7735H224.145V38.0294C224.145 30.7794 226.497 24.63 231.202 19.581C235.908 14.4889 241.612 11.9429 248.316 11.9429C255.228 11.9429 261.432 14.9636 266.928 21.0051C273.049 14.9636 279.17 11.9429 285.29 11.9429C293.077 11.9429 299.323 14.8773 304.028 20.7462C307.734 25.3204 309.587 31.9877 309.587 40.7478V77.7735Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter4_d_454_451)">
              <path
                d="M383.84 48.8398H348.426C347.883 46.9843 347.614 45.2796 347.614 43.7262C347.614 41.5686 347.988 39.4541 348.739 37.3826H360.605C359.772 32.5925 357.169 30.1974 352.796 30.1974C349.884 30.1974 347.509 31.492 345.677 34.0811C343.846 36.6272 342.928 39.907 342.928 43.9201C342.928 48.1062 343.805 51.4938 345.552 54.083C347.342 56.6718 349.673 57.9666 352.548 57.9666C354.879 57.9666 357.128 56.7586 359.293 54.342L372.596 70.9129C366.391 75.9617 359.5 78.4865 351.923 78.4865C342.68 78.4865 334.914 75.2711 328.626 68.8417C322.339 62.4116 319.195 54.4498 319.195 44.9562C319.195 35.5053 322.36 27.5219 328.689 21.0057C335.059 14.4896 342.867 11.2314 352.11 11.2314C361.145 11.2314 368.726 14.4248 374.845 20.8115C381.009 27.1551 384.088 34.9873 384.088 44.3087C384.088 45.2579 384.006 46.7683 383.84 48.8398Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter5_d_454_451)">
              <path
                d="M449.241 77.8068H425.88V41.3632C425.88 37.6521 424.402 35.7969 421.446 35.7969C418.534 35.7969 417.076 37.6521 417.076 41.3632V77.8068H393.715V41.8168C393.715 33.4016 396.403 26.3244 401.771 20.585C407.144 14.8024 413.722 11.9111 421.51 11.9111C430.42 11.9111 437.562 15.2556 442.931 21.9444C447.138 27.1659 449.241 34.4371 449.241 43.7584V77.8068Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter6_d_454_451)">
              <path
                d="M482.221 0.195312V24.9223H492.714V50.4906H482.221C482.221 55.6259 482.992 59.1648 484.531 61.1067C486.115 63.0484 488.986 64.0194 493.152 64.0194V89.4586C491.942 89.5014 491.052 89.523 490.468 89.523C485.18 89.523 480.182 88.25 475.476 85.7042C470.81 83.1581 467.066 79.7057 464.232 75.3471C460.654 69.7805 458.863 62.4011 458.863 53.2096V0.195312H482.221Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter7_d_454_451)">
              <path
                d="M567.393 48.8398H531.979C531.436 46.9843 531.167 45.2796 531.167 43.7262C531.167 41.5686 531.544 39.4541 532.288 37.3826H544.161C543.325 32.5925 540.722 30.1974 536.352 30.1974C533.437 30.1974 531.062 31.492 529.234 34.0811C527.399 36.6272 526.481 39.907 526.481 43.9201C526.481 48.1062 527.358 51.4938 529.105 54.083C530.895 56.6718 533.226 57.9666 536.101 57.9666C538.432 57.9666 540.681 56.7586 542.846 54.342L556.149 70.9129C549.944 75.9617 543.057 78.4865 535.476 78.4865C526.233 78.4865 518.466 75.2711 512.183 68.8417C505.893 62.4116 502.75 54.4498 502.75 44.9562C502.75 35.5053 505.913 27.5219 512.24 21.0057C518.612 14.4896 526.417 11.2314 535.663 11.2314C544.701 11.2314 552.279 14.4248 558.395 20.8115C564.562 27.1551 567.641 34.9873 567.641 44.3087C567.641 45.2579 567.559 46.7683 567.393 48.8398Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter8_d_454_451)">
              <path
                d="M611.424 12.6231V38.5153C609.797 37.566 608.319 37.091 606.99 37.091C602.743 37.091 600.619 40.4573 600.619 47.1891V77.8068H577.258V42.5284C577.258 33.2074 579.718 25.785 584.628 20.2614C589.541 14.6945 596.099 11.9111 604.302 11.9111C606.137 11.9111 608.505 12.1485 611.424 12.6231Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter9_d_454_451)">
              <path
                d="M665.992 12.1699V37.674C662.703 37.674 660.437 38.3212 659.186 39.6156C657.98 40.867 657.209 43.3697 656.876 47.1241C655.792 59.5958 651.13 68.3991 642.886 73.5344C638.513 76.2101 632.309 77.5475 624.272 77.5475H621.027V51.9143H622.087C625.832 51.9143 628.496 51.1163 630.083 49.5191C631.704 47.8792 632.642 45.0313 632.893 40.9748C633.226 34.3724 633.81 29.7334 634.64 27.0579C635.513 24.3824 637.263 21.7284 639.886 19.0961C644.47 14.4786 650.817 12.1699 658.935 12.1699H665.992Z"
                fill="white"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_454_451"
              x="0"
              y="11.2632"
              width="82.1195"
              height="86.2899"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_454_451"
              x="74.6367"
              y="0"
              width="82.2445"
              height="108.816"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_454_451"
              x="149.387"
              y="0"
              width="82.2445"
              height="108.816"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_454_451"
              x="224.145"
              y="11.9429"
              width="102.541"
              height="84.9306"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_454_451"
              x="319.195"
              y="11.2314"
              width="81.9906"
              height="86.3549"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_d_454_451"
              x="393.715"
              y="11.9111"
              width="72.6273"
              height="84.9955"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter6_d_454_451"
              x="458.863"
              y="0.195312"
              width="51.3891"
              height="108.428"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter7_d_454_451"
              x="502.75"
              y="11.2314"
              width="81.9906"
              height="86.3549"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter8_d_454_451"
              x="577.258"
              y="11.9111"
              width="51.268"
              height="84.9955"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <filter
              id="filter9_d_454_451"
              x="621.027"
              y="12.1699"
              width="62.0648"
              height="84.4774"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="12" dy="14" />
              <feGaussianBlur stdDeviation="2.55" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_454_451"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_454_451"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_454_451">
              <rect width="666" height="93" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="px-20 mt-5 text-base text-center text-white">
          We help app developers to showcase their apps
        </p>
      </div>
      <div className="flex flex-col gap-10 mt-20 jus:flex tify-center md:flex-row lg:gap-20">
        <div className="flex flex-col justify-center md:w-56">
          <h2 className="text-3xl text-center text-white md:text-start">
            Let’s grow <br /> your app
          </h2>
          <p className="px-20 mt-8 text-sm text-center text-white md:px-0 md:mt-4 md:text-start">
            Have and mobile app to launch or need to update existing app’s
            website{" "}
            <a href="#contact" className="underline ">
              Let’s Talk
            </a>
          </p>
        </div>
        <div className="flex flex-col px-5 md:w-32">
          <ul className="flex flex-row flex-wrap justify-center gap-4 text-sm text-white md:flex-col">
            <a href="/">
              <li>Home</li>
            </a>
            <a href="#services">
              <li>Services</li>
            </a>
            <a href="#projects">
              <li>Projects</li>
            </a>
            <a href="#whyus">
              <li>WhyUs</li>
            </a>
            <a href="#contact">
              <li>Contact Us</li>
            </a>
          </ul>
        </div>
        <div className="flex flex-col px-5 md:w-40 ">
          <ul className="flex flex-row flex-wrap justify-center gap-4 text-sm text-white md:flex-col ">
            <li>Web Design</li>
            <li>Web Development</li>
            <li>SEO Optimization</li>
            <li>Redesign</li>
          </ul>
        </div>
        <div className="flex flex-row justify-center gap-4 md:gap-2 md:w-10 md:flex-col ">
          <a href="">
            <svg
              className="social-icons"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.5" cy="19.5" r="19" stroke="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.1429 11H15.8571C13.1746 11 11 13.1746 11 15.8571V23.1429C11 25.8253 13.1746 28 15.8571 28H23.1429C25.8253 28 28 25.8253 28 23.1429V15.8571C28 13.1746 25.8253 11 23.1429 11Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5022 23.1426C17.4903 23.1426 15.8594 21.5117 15.8594 19.4998C15.8594 17.4878 17.4903 15.8569 19.5022 15.8569C21.5142 15.8569 23.1451 17.4878 23.1451 19.4998C23.1451 20.4659 22.7613 21.3925 22.0781 22.0757C21.3949 22.7588 20.4683 23.1426 19.5022 23.1426Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.3588 13.4284C23.6882 13.4284 23.1445 13.972 23.1445 14.6426C23.1445 15.3133 23.6882 15.8569 24.3588 15.8569C25.0294 15.8569 25.5731 15.3133 25.5731 14.6426C25.5731 13.972 25.0294 13.4284 24.3588 13.4284Z"
                fill="black"
              />
              <path
                d="M24.3571 14.0357C24.0218 14.0357 23.75 14.3075 23.75 14.6429C23.75 14.9782 24.0218 15.25 24.3571 15.25C24.6925 15.25 24.9643 14.9782 24.9643 14.6429C24.9643 14.3075 24.6925 14.0357 24.3571 14.0357Z"
                stroke="white"
                stroke-width="2.4"
                stroke-linecap="round"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/appmenters/"
            target="_blank"
          >
            <svg
              className="social-icons"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.5" cy="19.5" r="19" stroke="white" />
              <path
                d="M15.5007 16.7275C16.1785 16.7275 16.728 16.178 16.728 15.5002C16.728 14.8224 16.1785 14.2729 15.5007 14.2729C14.8229 14.2729 14.2734 14.8224 14.2734 15.5002C14.2734 16.178 14.8229 16.7275 15.5007 16.7275Z"
                fill="white"
              />
              <path
                d="M14.2734 18.3636C14.2734 17.9117 14.6398 17.5454 15.0916 17.5454H15.9098C16.3617 17.5454 16.728 17.9117 16.728 18.3636V24.909C16.728 25.3609 16.3617 25.7272 15.9098 25.7272H15.0916C14.6398 25.7272 14.2734 25.3609 14.2734 24.909V18.3636Z"
                fill="white"
              />
              <path
                d="M19.1815 25.7272H19.9996C20.4515 25.7272 20.8178 25.3609 20.8178 24.909V21.2272C20.8178 20 23.2724 19.1818 23.2724 20.8181V24.9094C23.2724 25.3613 23.6387 25.7272 24.0906 25.7272H24.9087C25.3606 25.7272 25.7269 25.3609 25.7269 24.909V20C25.7269 18.3636 24.4996 17.5454 22.8633 17.5454C21.2269 17.5454 20.8178 18.7727 20.8178 18.7727V18.3636C20.8178 17.9117 20.4515 17.5454 19.9996 17.5454H19.1815C18.7296 17.5454 18.3633 17.9117 18.3633 18.3636V24.909C18.3633 25.3609 18.7296 25.7272 19.1815 25.7272Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.5455 11C27.9011 11 29 12.0989 29 13.4545V26.5455C29 27.9011 27.9011 29 26.5455 29H13.4545C12.0989 29 11 27.9011 11 26.5455V13.4545C11 12.0989 12.0989 11 13.4545 11H26.5455ZM26.5455 12.6364C26.9973 12.6364 27.3636 13.0027 27.3636 13.4545V26.5455C27.3636 26.9973 26.9973 27.3636 26.5455 27.3636H13.4545C13.0027 27.3636 12.6364 26.9973 12.6364 26.5455V13.4545C12.6364 13.0027 13.0027 12.6364 13.4545 12.6364H26.5455Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="https://dribbble.com/appmenters" target="_blank">
            <svg
              className="social-icons"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.5" cy="19.5" r="19" stroke="white" />
              <path
                d="M26.4431 14.8432C25.126 16.4074 23.4399 17.6194 21.5172 18.3504L21.4346 18.3777C21.6447 18.8062 21.8456 19.2397 22.0298 19.678C22.0998 19.8355 22.1607 19.9931 22.2223 20.1415C22.8714 20.0701 23.6248 20.0295 24.3873 20.0295C25.8318 20.0295 27.242 20.1751 28.6045 20.4531L28.4694 20.43C28.4554 18.3014 27.6943 16.3542 26.436 14.8327L26.448 14.8474L26.4431 14.8432ZM17.4001 11.796C18.5169 13.3014 19.6239 15.0168 20.6196 16.803L20.7442 17.046C22.6431 16.4102 24.2375 15.2878 25.4313 13.8251L25.446 13.8069C23.8727 12.4023 21.7854 11.5439 19.4986 11.5439C18.7543 11.5439 18.031 11.6349 17.3392 11.8065L17.4008 11.7939L17.4001 11.796ZM10.7197 18.676C13.6737 18.6606 16.5277 18.2629 19.2451 17.5305L19.012 17.5837C17.8553 15.5539 16.756 13.8601 15.5727 12.2315L15.6868 12.3967C13.1689 13.6018 11.3267 15.8739 10.7316 18.6109L10.7211 18.6704L10.7197 18.676ZM12.837 26.5111C14.5315 23.793 17.0451 21.7225 20.0286 20.6239L20.1274 20.5924C20.2457 20.5532 20.3633 20.5189 20.4817 20.4874C20.2541 19.9756 20.009 19.4665 19.7535 18.9652C17.0311 19.7732 13.9034 20.2388 10.6671 20.2388C10.6209 20.2388 10.5754 20.2388 10.5299 20.2388H10.5369L10.5334 20.5119C10.5334 20.514 10.5334 20.5161 10.5334 20.5182C10.5334 22.8267 11.4086 24.9308 12.8447 26.5174L12.8377 26.5097L12.837 26.5111ZM23.0044 28.7685C22.5269 26.1785 21.8631 23.8973 20.9998 21.7176L21.088 21.9704L21.0306 21.9879C18.01 22.9303 15.5509 24.9231 14.0273 27.5299L13.9958 27.588C15.4977 28.7699 17.4162 29.4834 19.5014 29.4834C20.7652 29.4834 21.9675 29.2215 23.0569 28.7489L22.9988 28.7713L23.0044 28.7685ZM28.3546 21.9368C27.2546 21.6049 25.9907 21.4137 24.6821 21.4137C24.0071 21.4137 23.344 21.4649 22.6964 21.5629L22.7692 21.5538C23.4554 23.3491 24.0589 25.4951 24.4748 27.7077L24.5126 27.9486C26.525 26.5762 27.9387 24.4526 28.3511 21.99L28.3581 21.9375L28.3546 21.9368ZM19.5 31C13.7011 31 9 26.2989 9 20.5C9 14.7011 13.7011 10 19.5 10C25.2989 10 30 14.7011 30 20.5C29.9923 26.2954 25.2954 30.9916 19.5 31Z"
                fill="#E1DDDD"
              />
            </svg>
          </a>
          <a href="mailto:info.appmenters@gmail.com">
            <svg
              className="social-icons"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.5" cy="19.5" r="19" stroke="white" />
              <path
                d="M28.9536 14.9931C28.8724 14.5881 28.6912 14.2175 28.4381 13.9113C28.3847 13.8446 28.329 13.7848 28.2685 13.7251C27.8201 13.2786 27.1908 13 26.5032 13H13.4968C12.8093 13 12.1822 13.2786 11.7316 13.7251C11.6713 13.7848 11.6156 13.8446 11.5621 13.9113C11.3088 14.2174 11.1276 14.5881 11.0487 14.9931C11.0163 15.1494 11 15.311 11 15.4743V24.526C11 24.8732 11.0743 25.207 11.2069 25.5085C11.3298 25.7962 11.5111 26.0566 11.7316 26.2749C11.7875 26.3302 11.843 26.381 11.9036 26.4315C12.3355 26.7859 12.8931 27 13.4968 27H26.5032C27.1072 27 27.6672 26.7859 28.0966 26.4292C28.157 26.381 28.2128 26.3302 28.2685 26.2749C28.489 26.0566 28.6702 25.7962 28.7957 25.5085V25.5062C28.9282 25.2048 29 24.8732 29 24.526V15.4743C29 15.311 28.984 15.1494 28.9536 14.9931ZM12.6351 14.6202C12.8582 14.3994 13.1577 14.2658 13.4968 14.2658H26.5032C26.8423 14.2658 27.1445 14.3994 27.365 14.6202C27.4044 14.6595 27.4416 14.7034 27.4744 14.7469L20.6573 20.6341C20.4693 20.7974 20.237 20.8781 20 20.8781C19.7654 20.8781 19.5333 20.7974 19.3428 20.6341L12.5283 14.7444C12.5585 14.7009 12.5956 14.6595 12.6351 14.6202ZM12.2774 24.526V16.0542L17.2106 20.3188L12.2799 24.5788C12.2774 24.5626 12.2774 24.5444 12.2774 24.526ZM26.5032 25.734H13.4968C13.276 25.734 13.0694 25.6765 12.8931 25.5754L18.0954 21.0829L18.5811 21.5016C18.9876 21.8516 19.4962 22.0288 20 22.0288C20.5065 22.0288 21.0151 21.8516 21.4215 21.5016L21.9069 21.0829L27.1072 25.5754C26.9306 25.6765 26.724 25.734 26.5032 25.734ZM27.7227 24.526C27.7227 24.5444 27.7227 24.5626 27.7203 24.5788L22.7895 20.3212L27.7227 16.0565V24.526Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>

      <p className="mt-10 mb-10 text-sm text-center text-white md:mb-0">
        &copy; {year} appmenters. All rights reserved.
      </p>
    </section>
  );
}
