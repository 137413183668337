import React, { useEffect } from "react";
import "../App.css";

const Hero = () => {
  return (
    <section className="flex flex-col items-center justify-center hero-gap homeHeight">
      <h1 className="px-10 md:px-0 poppins-medium animated-text">
        We build <br /> web showcases for <br />{" "}
        <span className="text-color">mobile apps</span>
      </h1>
      <p className="px-8 text-center md:px-0 poppins-regular plineHeight">
        At appmenters, we specialize in helping{" "}
        <span className="text-color">app developers</span> create captivating{" "}
        <span className="text-color">web showcases</span> for their mobile
        applications. Our tailored solutions ensure your app stands out with
        responsive design, SEO optimization, seamless integration, and
        continuous support. Let us elevate your app's online presence and{" "}
        <span className="text-color">drive success!</span>
      </p>
      <a href="#contact" className="btn2 poppins-medium">Let's Talk</a>
    </section>
  );
};

export default Hero;
