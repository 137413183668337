import React, { useEffect } from "react";
import "./App.css";
import Home from "../src/home";

import Blog from "./pages/blog";
import ReadBlog from "./pages/readBlog";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ogimg from '../src/assets/images/ogimage.png'

function App() {
  useEffect(() => {
    document.title = "appmenters - Web Showcases for Mobile Apps";
    <meta
      name="description"
      content="We build
    web showcases for
    mobile apps"
    />;
    <meta name="keywords" content="webdevelopment, appwebsite, webshowcase" />;
    <meta name="language" content="en" />;
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />;
    <meta charset="UTF-8" />;
    <meta name="author" content="appmenters.com" />;
    <meta property="og:title" content="appmenters - Web Showcases for Mobile Apps" />;
    <meta
      property="og:description"
      content="We build
    web showcases for
    mobile apps"
    />;
    <meta property="og:image" content={ogimg} />;
    <meta property="og:url" content="https://appmenters.com/" />;
    <meta property="og:type" content="website" />;
  }, []);

  return (
    <Router>
      <div className="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/read-blog" element={<ReadBlog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
