
import project1 from "../assets/images/ProjectImages/project1.webp";
import project2 from "../assets/images/ProjectImages/project2.webp";
import project3 from "../assets/images/ProjectImages/project3.webp";
import project4 from "../assets/images/ProjectImages/project4.webp";
import project5 from "../assets/images/ProjectImages/project5.webp";

import React, { useRef } from "react";

export default function Projects(){
  const scrollContainerRef = useRef(null);

  const handleScroll = (e) => {
    if (scrollContainerRef.current) {
      // Check if the scrollLeft is already at 0 and the scroll direction is upward
      if (scrollContainerRef.current.scrollLeft === 0 && e.deltaY < 0) {
        return; // Don't scroll horizontally if already at the beginning and scrolling upwards
      }
      // Otherwise, scroll horizontally
      scrollContainerRef.current.scrollLeft += e.deltaY;
    }
  };

  const handleScrollBack = () => {
    if (scrollContainerRef.current.scrollLeft > 0) {
      scrollContainerRef.current.scrollTo({
        left: 0,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const handleScrollEnd = () => {
    if (window.innerWidth > 690) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + 560,
        behavior: "smooth", // Smooth scrolling
      });
    }else if(window.innerWidth < 690 && window.innerWidth > 573){
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + 465,
        behavior: "smooth", // Smooth scrolling
      });
    }
     else {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + 362,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  return (
    <section
      id="projects"
      className="h-full pt-12 pb-12 bg-white pe-0 ps-3 md:ps-12 md:h-screen poppins-medium"
    >
      <div className="flex flex-col items-center justify-around gap-0 md:flex-row md:gap-80">
        <h4 className="text-xl text-black">- Projects -</h4>
        <h2 className="mt-10 text-2xl text-black md:mt-0 md:text-3xl w-72 text-start md:text-end">
          A Showcase of Our Finest Creations
        </h2>
      </div>
      <div className="scroll-container" onWheel={handleScroll}>
        <div
          className="flex flex-row justify-start gap-6 mt-10 md:mt-24 overflowManage "
          ref={scrollContainerRef}
        >
          <div className="project-card">
            <img src={project1} className="projectimg" />
          </div>
          <div className="project-card">
            <img src={project2} className="projectimg" />
          </div>
          <div className="project-card">
            <img src={project3} className="projectimg" />
          </div>
          <div className="project-card">
            <img src={project4} className="projectimg" />
          </div>
          <div className="project-card">
            <img src={project5} className="projectimg" />
          </div>
        </div>
        <button className="mt-4 md:mt-10" onClick={handleScrollBack}>
          <svg
            className="backicon"
            height="108"
            viewBox="0 0 108 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="54"
              cy="54"
              r="53"
              fill="white"
              stroke="black"
              stroke-width="2"
            />
            <path
              d="M67.6471 83.4897C68.4653 84.2277 69.7299 84.1539 70.4737 83.3421C71.2176 82.5302 71.1432 81.2756 70.325 80.5376L41.5379 54.707C40.7941 54.0428 40.7941 53.0834 41.5379 52.4192L70.325 27.4742C71.1432 26.7362 71.2176 25.4816 70.5481 24.6697C69.8043 23.8579 68.5397 23.7841 67.7215 24.4483L38.9344 49.4671C36.4053 51.6811 36.3309 55.3712 38.86 57.6591L67.6471 83.4897Z"
              fill="black"
            />
          </svg>
        </button>
        <button
          className="mt-4 md:mt-10 ms-4 md:ms-10"
          onClick={handleScrollEnd}
        >
          <svg
            className="backicon"
            height="108"
            viewBox="0 0 108 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="54"
              cy="54"
              r="53"
              fill="white"
              stroke="black"
              stroke-width="2"
            />
            <path
              d="M40.3529 24.5103C39.5347 23.7723 38.2701 23.8461 37.5263 24.6579C36.7824 25.4698 36.8568 26.7244 37.675 27.4624L66.4621 53.293C67.2059 53.9572 67.2059 54.9166 66.4621 55.5808L37.675 80.5258C36.8568 81.2638 36.7824 82.5184 37.4519 83.3303C38.1957 84.1421 39.4603 84.2159 40.2785 83.5517L69.0656 58.5329C71.5947 56.3189 71.6691 52.6288 69.14 50.3409L40.3529 24.5103Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </section>
  );
};
