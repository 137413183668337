import React, { useEffect, useState, useRef } from "react";
import "../App.css";

export default function Nav() {
  // Use useState to manage menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuIconRef = useRef(null);
  const menuModelRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 900) {
        menuModelRef.current.style.display = "none";
        menuIconRef.current.style.display = "none";
      } else {
        if (menuModelRef.current.style.display === "none") {
          menuIconRef.current.style.display = "block";
        }
      }
    });
  }, [window.innerWidth]);

  const toggleMenu = () => {
    if (window.innerWidth < 900) {
      setIsMenuOpen(!isMenuOpen);
      if (isMenuOpen) {
        // Close the menu
        menuModelRef.current.style.display = "none";
        menuIconRef.current.style.display = "block";
      } else {
        // Open the menu
        menuModelRef.current.style.display = "block";
        menuIconRef.current.style.display = "none";
      }
    }
  };

  return (
    <nav className="flex items-center justify-between h-12 px-8 py-12 md:px-16">
      <div>
        <a href="/" className="logo">
          <svg
            width="180"
            height="30"
            viewBox="0 0 216 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <svg
              width="180"
              height="30"
              viewBox="0 0 216 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3362 17.0596V25.1404C11.715 25.2519 11.1478 25.3075 10.6347 25.3075C7.59619 25.3075 5.06414 24.2775 3.03848 22.2171C1.01283 20.1568 0 17.5746 0 14.4704C0 11.4496 1.01958 8.88829 3.05874 6.78629C5.1114 4.68429 7.60295 3.6333 10.5334 3.6333C13.8014 3.6333 16.374 4.62166 18.2511 6.59837C20.1418 8.56116 21.0871 11.2477 21.0871 14.6583V24.8691H13.5111V15.2221C13.5111 14.1224 13.2477 13.2454 12.7211 12.5912C12.208 11.9368 11.5125 11.6097 10.6347 11.6097C9.83791 11.6097 9.1492 11.9091 8.56851 12.5076C7.98781 13.1062 7.69748 13.8162 7.69748 14.6375C7.69748 15.5005 7.96081 16.2105 8.48748 16.7673C9.01415 17.3241 9.68262 17.6025 10.4929 17.6025C11.1546 17.6025 11.769 17.4216 12.3362 17.0596Z"
                fill="#2603FE"
              />
              <path
                d="M31.783 28.9408H24.207V11.568C24.207 8.38008 25.0173 5.78403 26.6378 3.77942C27.5696 2.62401 28.7513 1.70527 30.1827 1.02315C31.6277 0.341056 33.1063 0 34.6189 0C37.6303 0 40.1692 1.04403 42.2354 3.1321C44.3015 5.22019 45.3346 7.78152 45.3346 10.8162C45.3346 13.7673 44.3083 16.3149 42.2556 18.4587C40.203 20.6024 37.7723 21.6742 34.9633 21.6742C34.5042 21.6742 33.8289 21.6254 32.9376 21.5281V12.9251C33.5588 13.4402 34.1801 13.6978 34.8012 13.6978C35.5844 13.6978 36.2529 13.3986 36.8066 12.7999C37.3739 12.2013 37.6574 11.4914 37.6574 10.6701C37.6574 9.84872 37.3671 9.15268 36.7864 8.58192C36.2056 7.99734 35.5102 7.70498 34.7 7.70498C32.7552 7.70498 31.783 9.0901 31.783 11.8603V28.9408Z"
                fill="#2603FE"
              />
              <path
                d="M56.0252 28.9408H48.4492V11.568C48.4492 8.38008 49.2594 5.78403 50.88 3.77942C51.8118 2.62401 52.9935 1.70527 54.4249 1.02315C55.8699 0.341056 57.3485 0 58.8611 0C61.8725 0 64.4114 1.04403 66.4775 3.1321C68.5437 5.22019 69.5768 7.78152 69.5768 10.8162C69.5768 13.7673 68.5504 16.3149 66.4978 18.4587C64.4452 20.6024 62.0145 21.6742 59.2055 21.6742C58.7464 21.6742 58.0711 21.6254 57.1799 21.5281V12.9251C57.801 13.4402 58.4222 13.6978 59.0434 13.6978C59.8266 13.6978 60.4951 13.3986 61.0487 12.7999C61.6161 12.2013 61.8996 11.4914 61.8996 10.6701C61.8996 9.84872 61.6092 9.15268 61.0286 8.58192C60.4478 7.99734 59.7524 7.70498 58.9422 7.70498C56.9974 7.70498 56.0252 9.0901 56.0252 11.8603V28.9408Z"
                fill="#2603FE"
              />
              <path
                d="M100.406 25.0882H92.8304V13.8543C92.8304 12.9913 92.7426 12.3929 92.567 12.0587C92.3915 11.7246 92.0742 11.5575 91.615 11.5575C90.7642 11.5575 90.3388 12.3301 90.3388 13.8753V25.0882H82.7629V13.8543C82.7629 12.9913 82.6683 12.3929 82.4792 12.0587C82.3038 11.7246 81.9864 11.5575 81.5272 11.5575C80.69 11.5575 80.2713 12.3301 80.2713 13.8753V25.0882H72.6953V12.2675C72.6953 9.92883 73.4584 7.94516 74.9844 6.31645C76.5104 4.67384 78.3605 3.85254 80.5347 3.85254C82.7765 3.85254 84.7885 4.82696 86.5711 6.77584C88.5562 4.82696 90.5414 3.85254 92.5264 3.85254C95.0519 3.85254 97.0775 4.79913 98.6035 6.69232C99.8053 8.16788 100.406 10.3186 100.406 13.1444V25.0882Z"
                fill="black"
              />
              <path
                d="M124.489 15.7548H113.004C112.827 15.1562 112.74 14.6063 112.74 14.1052C112.74 13.4092 112.862 12.7271 113.105 12.0589H116.953C116.683 10.5137 115.839 9.7411 114.421 9.7411C113.477 9.7411 112.706 10.1587 112.112 10.9939C111.518 11.8152 111.221 12.8732 111.221 14.1678C111.221 15.5181 111.505 16.6109 112.071 17.4461C112.652 18.2812 113.408 18.6989 114.341 18.6989C115.097 18.6989 115.826 18.3092 116.528 17.5297L120.843 22.8751C118.83 24.5038 116.595 25.3182 114.138 25.3182C111.14 25.3182 108.621 24.281 106.582 22.207C104.543 20.1328 103.523 17.5644 103.523 14.502C103.523 11.4533 104.55 8.87805 106.602 6.77605C108.669 4.67406 111.201 3.62305 114.198 3.62305C117.129 3.62305 119.587 4.65317 121.572 6.7134C123.571 8.75972 124.57 11.2862 124.57 14.2931C124.57 14.5993 124.543 15.0866 124.489 15.7548Z"
                fill="black"
              />
              <path
                d="M145.7 25.0989H138.123V13.3429C138.123 12.1458 137.644 11.5474 136.685 11.5474C135.741 11.5474 135.268 12.1458 135.268 13.3429V25.0989H127.691V13.4893C127.691 10.7747 128.563 8.49173 130.304 6.64031C132.047 4.77496 134.18 3.84229 136.706 3.84229C139.596 3.84229 141.912 4.92113 143.653 7.07881C145.018 8.76318 145.7 11.1087 145.7 14.1156V25.0989Z"
                fill="black"
              />
              <path
                d="M156.396 0.0629883V8.03944H159.799V16.2873H156.396C156.396 17.9438 156.646 19.0854 157.145 19.7118C157.659 20.3382 158.59 20.6514 159.941 20.6514V28.8576C159.549 28.8714 159.26 28.8784 159.07 28.8784C157.356 28.8784 155.735 28.4677 154.208 27.6465C152.695 26.8252 151.481 25.7115 150.562 24.3055C149.401 22.5098 148.82 20.1294 148.82 17.1644V0.0629883H156.396Z"
                fill="black"
              />
              <path
                d="M184.02 15.7548H172.534C172.358 15.1562 172.271 14.6063 172.271 14.1052C172.271 13.4092 172.393 12.7271 172.635 12.0589H176.485C176.214 10.5137 175.37 9.7411 173.953 9.7411C173.007 9.7411 172.237 10.1587 171.644 10.9939C171.049 11.8152 170.751 12.8732 170.751 14.1678C170.751 15.5181 171.036 16.6109 171.602 17.4461C172.183 18.2812 172.939 18.6989 173.871 18.6989C174.627 18.6989 175.357 18.3092 176.059 17.5297L180.373 22.8751C178.361 24.5038 176.127 25.3182 173.668 25.3182C170.671 25.3182 168.152 24.281 166.114 22.207C164.074 20.1328 163.055 17.5644 163.055 14.502C163.055 11.4533 164.081 8.87805 166.133 6.77605C168.199 4.67406 170.73 3.62305 173.729 3.62305C176.66 3.62305 179.118 4.65317 181.102 6.7134C183.102 8.75972 184.1 11.2862 184.1 14.2931C184.1 14.5993 184.074 15.0866 184.02 15.7548Z"
                fill="black"
              />
              <path
                d="M198.3 4.07196V12.4243C197.772 12.1181 197.293 11.9648 196.862 11.9648C195.484 11.9648 194.795 13.0507 194.795 15.2223V25.0989H187.219V13.7188C187.219 10.7121 188.017 8.31773 189.609 6.53591C191.203 4.74015 193.33 3.84229 195.99 3.84229C196.585 3.84229 197.353 3.91884 198.3 4.07196Z"
                fill="black"
              />
              <path
                d="M215.997 3.92578V12.1529C214.931 12.1529 214.196 12.3617 213.79 12.7792C213.399 13.1829 213.149 13.9902 213.041 15.2013C212.689 19.2244 211.177 22.0642 208.503 23.7208C207.085 24.5839 205.073 25.0153 202.466 25.0153H201.414V16.7466H201.758C202.972 16.7466 203.836 16.4891 204.351 15.9739C204.877 15.4449 205.181 14.5262 205.262 13.2177C205.37 11.0879 205.56 9.59143 205.829 8.72836C206.112 7.86528 206.68 7.00917 207.53 6.16003C209.017 4.67053 211.076 3.92578 213.708 3.92578H215.997Z"
                fill="black"
              />
            </svg>
          </svg>
        </a>
      </div>

      <div className="flex items-center gap-10 poppins-regular nav-links">
        <a href="/" className="nav-link" onClick={toggleMenu}>
          Home
        </a>
        <a href="#services" className="nav-link" onClick={toggleMenu}>
          Services
        </a>
        <a href="#projects" className="nav-link" onClick={toggleMenu}>
          Projects
        </a>
       
        <a href="#contact" onClick={toggleMenu}>
          <button className="btn1">Contact Us</button>
        </a>
      </div>

      <div ref={menuModelRef} className="menu-model" id="menu-model">
        <button className="absolute right-6" onClick={toggleMenu}>
          <svg
            width="20"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 23L13 13M13 13L3 3M13 13L23 3M13 13L3 23"
              stroke="white"
              strokeWidth="5"
              strokelinecap="round"
              strokelinejoin="round"
            />
          </svg>
        </button>
        <div className="flex flex-col items-center gap-10 poppins-regular">
          <a href="/" className="text-white ">
            Home
          </a>
          <a href="#services" className="text-white ">
            Services
          </a>
          <a href="#projects" className="text-white ">
            Projects
          </a>
          {/* <Link href="/" className="text-white ">
          About Us
        </Link> */}
          <a href="#contact" className="text-white ">
            Contact Us
          </a>
        </div>
      </div>

      <div ref={menuIconRef} id="menu-open" className="menu-open">
        <button onClick={toggleMenu}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 46 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <svg
              width="26"
              height="36"
              viewBox="0 0 46 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3H43M3 18H43M3 33H43"
                stroke="black"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </svg>
        </button>
      </div>
    </nav>
  );
}
