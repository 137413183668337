import { useEffect, useRef } from 'react';
import whyusimg from '../assets/images/whyusimg.svg'
export default function Whyus() {

  const whyusBoxRef1 = useRef(null);
  const whyusBoxRef2 = useRef(null);
  const whyusBoxRef3 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const whyusSection = document.getElementById('whyus');
      if (!whyusSection) return;

      const whyusBox1 = whyusBoxRef1.current;
      const whyusBox2 = whyusBoxRef2.current;
      const whyusBox3 = whyusBoxRef3.current;

      const rect = whyusSection.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      
      // Check if the services section is in the viewport
      if (rect.top <= windowHeight && rect.bottom >= 0) {
        // Add the "fade" class to trigger the animation for each element
        if (whyusBox1) whyusBox1.classList.add('fade');
        if (whyusBox2) whyusBox2.classList.add('fade');
        if (whyusBox3) whyusBox3.classList.add('fade');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    
    <section
      id="whyus"
      className="flex flex-col items-center justify-center h-full p-16 mt-0 bg-black md:mt-28 md:h-screen poppins-medium"
    >
      <h4 className="text-xl text-white ">- Why Us -</h4>

      <div className="flex flex-row justify-around w-full mt-20">
        <div className="flex-col hidden gap-10 md:flex md:visible">
          <h2 className="text-white">
            Choose Us for <br /> Excellence
          </h2>
          {/* <svg
            className="whyusimg"
            height="400"
            viewBox="0 0 383 400"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M368.62 168.178H269.571C266.52 168.178 264.703 171.575 264.703 174.643V393.809C264.703 396.862 266.52 399.178 269.571 399.178H368.62C371.67 399.178 373.216 396.862 373.216 393.809V174.643C373.216 171.575 371.67 168.178 368.62 168.178Z"
              fill="white"
            />
            <path
              d="M242.51 290.787C242.51 287.722 240.339 284.911 237.275 284.911H137.394C134.33 284.911 131.531 287.723 131.531 290.787V394.779C131.531 397.842 134.33 400 137.394 400H237.275C240.34 400 242.51 397.842 242.51 394.779V290.787Z"
              fill="white"
            />
            <path
              d="M110.979 347.509C110.979 344.444 108.809 341.633 105.744 341.633H5.86297C2.79831 341.633 0 344.445 0 347.509V393.956C0 397.02 2.79831 399.178 5.86297 399.178H105.744C108.809 399.178 110.979 397.02 110.979 393.956V347.509Z"
              fill="white"
            />
            <path
              d="M29.5943 314.472C32.4025 314.472 35.3488 312.194 35.3488 309.385V284.101C35.3488 246.942 64.8617 215.857 102.022 215.857H135.593C138.403 215.857 140.573 214.431 140.573 211.623V177.531C140.573 138.135 172.732 104.879 212.129 104.879H212.915V117.01C212.915 118.789 213.84 120.364 215.49 121.027C216.017 121.24 216.495 121.342 217.038 121.342C218.193 121.342 219.286 120.877 220.123 120L235.404 103.988C237.006 102.311 236.998 99.6825 235.394 98.0063L220.093 81.9933C218.862 80.7059 217.141 80.304 215.492 80.9657C213.84 81.6275 212.915 83.2042 212.915 84.9831V95.8361H212.129C167.123 95.8361 129.064 132.525 129.064 177.531V206.815H102.022C59.252 206.815 23.8398 241.333 23.8398 284.102V309.386C23.8398 312.194 26.7861 314.472 29.5943 314.472Z"
              fill="white"
            />
            <path
              d="M318.137 22.5238C320.945 22.5238 323.892 20.2467 323.892 17.4368V5.08694C323.892 2.27712 320.945 0 318.137 0C315.329 0 312.383 2.27712 312.383 5.08694V17.4368C312.383 20.2467 315.329 22.5238 318.137 22.5238Z"
              fill="white"
            />
            <path
              d="M341.761 27.5377C342.502 27.92 343.295 28.1 344.076 28.1C345.924 28.1 347.706 27.0889 348.607 25.3321L354.326 14.1701C355.606 11.6702 354.617 8.60473 352.116 7.32395C349.616 6.03906 346.551 7.03212 345.27 9.53202L339.553 20.6924C338.272 23.1923 339.261 26.2578 341.761 27.5377Z"
              fill="white"
            />
            <path
              d="M289.3 24.329C290.166 26.1737 291.996 27.258 293.908 27.258C294.632 27.258 295.365 27.1026 296.064 26.7762C298.608 25.5818 299.703 22.5533 298.51 20.009L293.197 8.68012C292.006 6.13829 288.977 5.04659 286.432 6.23447C283.889 7.42893 282.794 10.4558 283.986 13.0001L289.3 24.329Z"
              fill="white"
            />
            <path
              d="M367.65 55.5544H357.469L358.188 43.9781C358.38 40.8427 355.889 38.291 352.747 38.291H283.794C280.652 38.291 278.16 40.8427 278.355 43.9781L279.072 55.5544H270.562C262.608 55.5544 256.152 62.1819 256.152 70.1354C256.152 74.3707 258.002 78.4703 261.258 81.2061L273.889 91.939L273.891 91.9842L282.066 98.9027C282.065 98.8929 282.063 98.8855 282.061 98.8764L282.066 98.8805L282.089 98.9101C284.778 112.346 294.696 123.058 307.532 126.953C310.082 127.728 311.838 130.07 312.025 132.728L313.915 151.857C313.99 152.926 313.143 153.38 312.07 153.38H295.749C294.726 153.38 294.298 155.124 294.298 156.143V159.842C294.298 160.865 294.726 160.779 295.749 160.779H340.792C341.813 160.779 341.978 160.865 341.978 159.842V156.143C341.978 155.124 341.813 153.38 340.792 153.38H324.473C323.4 153.38 322.552 152.928 322.626 151.859L324.518 132.504C324.704 129.845 326.459 127.685 329.009 126.909C341.281 123.182 350.88 113.099 354.053 100.768H354.074L354.078 100.695C354.077 100.705 354.06 100.682 354.057 100.69L362.53 93.464V93.4524L376.937 81.2867C380.174 78.5509 382.03 74.3665 382.03 70.1304C382.031 62.1786 375.585 55.5544 367.65 55.5544ZM266.029 75.6514C264.458 74.3205 263.55 72.1881 263.55 70.1362C263.55 66.2684 266.695 62.9538 270.562 62.9538H272.091H272.114H279.531H279.509H279.526L281.071 88.1945L273.26 81.7808L266.029 75.6514ZM318.06 102.278C306.738 102.278 297.559 93.0973 297.559 81.775C297.559 70.4494 306.738 61.2702 318.06 61.2702C329.386 61.2702 338.565 70.4494 338.565 81.775C338.566 93.0973 329.387 102.278 318.06 102.278ZM372.163 75.6514L363.193 83.235L355.378 89.6586L357.012 62.9538H357.036H357.014H364.432H364.454H367.649C371.515 62.9538 374.642 66.2684 374.642 70.1362C374.642 72.1872 373.735 74.3205 372.163 75.6514Z"
              fill="white"
            />
            <path
              d="M318.064 91.9482C323.682 91.9482 328.237 87.3935 328.237 81.7751C328.237 76.1567 323.682 71.6021 318.064 71.6021C312.445 71.6021 307.891 76.1567 307.891 81.7751C307.891 87.3935 312.445 91.9482 318.064 91.9482Z"
              fill="white"
            />
          </svg> */}
          <img src={whyusimg} width={300}/>
        </div>
        <div className="flex flex-col gap-20">
          <div ref={whyusBoxRef1} className="opacity-0 flex flex-col gap-2 lg:w-[500px] md:w-[350px] sm:w-[400px] w-auto">
            <h2 className="text-center text-white md:text-start">
              Customization and Personalization
            </h2>
            <p className="text-sm text-center text-white md:text-start">
              Experience the power of customization as we craft a unique digital
              presence that perfectly aligns with your brand identity and goals
            </p>
          </div>
          <div ref={whyusBoxRef2} className="opacity-0 flex flex-col gap-2 lg:w-[500px] md:w-[350px] sm:w-[400px] w-auto">
            <h2 className="text-center text-white md:text-start">
              User-centric design
            </h2>
            <p className="text-sm text-center text-white md:text-start">
              Prioritize a user-centric design approach to enhance the overall
              experience of visitors navigating through the app showcase
              websites.
            </p>
          </div>
          <div ref={whyusBoxRef3} className="opacity-0 flex flex-col gap-2 lg:w-[500px] md:w-[350px] sm:w-[400px] w-auto">
            <h2 className="text-center text-white md:text-start">
              Optimized for Search Engines
            </h2>
            <p className="text-sm text-center text-white md:text-start">
              Elevate your online visibility with our SEO expertise, positioning
              your brand prominently in search engine results
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
