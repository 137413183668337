import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';
import {getAuth} from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyAijX3Xuwe78AYL1e8NlHe4N5WGz72JiD0",
  authDomain: "appmenters-website.firebaseapp.com",
  projectId: "appmenters-website",
  storageBucket: "appmenters-website.appspot.com",
  messagingSenderId: "912993398630",
  appId: "1:912993398630:web:e531fb0927a6e8beb062a7",
  measurementId: "G-2X3BCEHQJW",
};

export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);